import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../../app/peticiones.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dns-construction',
  templateUrl: './dns-construction.component.html',
  styleUrls: ['./dns-construction.component.css'],
})
export class DnsConstructionComponent implements OnInit {
  Data: any = {};

  Texto_Idioma: any[] = [];

  constructor(
    private _servicio: PeticionesService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    window.scroll(0, 0);
    await this.cargarDatos();
  }

  async cargarDatos() {
    this.Cargar_Idioma();
  }

  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma'))
      .value;
    console.log('Oninit INICIO==>', btn_idioma);
    if (btn_idioma === 'ES') {
      this.Cambiar_Espanol();
    } else if (btn_idioma === 'EN') {
      this.Cambiar_Ingles();
    }
  }

  Cambiar_Espanol() {
    this.Data = {
      tema1: `¿Qué ofrecemos?`,
      descripcion1: `Nos especializamos en construcción desde cero, para residencial, comercial. Ofreciendo servicios de construcción e ingeniería.`,

      tema2: `Servicios`,

      servicio1: `Excavación / Trabajo de Tierras`,
      servicio2: `Pavimentación`,
      servicio3: `Concreto`,
      servicio4: `Demolición`,
      servicio5: `Servicios públicos subterráneos`,
      servicio6: `Obra nueva residencial y comercial`,
      servicio7: `Remodelación`,
      servicio8: `Plomería`,


      tema3: `Industrias que trabajamos`,
      industria1: `Hoteles`,
      industria2: `Hospitales`,
      industria3: `Escuelas`,
      industria4: `Comercial`,
      industria5: `Industrial`,

      tema4: `Mas servicios`,
    };
  }

  Cambiar_Ingles() {
    this.Data = {
      tema1: `What we offer?`,

      descripcion1: `We specialize in ground up construction, for residential, commercial. Offering services for General Contracting and Engineering.`,

      tema2: `Services`,

      servicio1: `Excavation / Earth Work`,
      servicio2: `Paving`,
      servicio3: `Concrete`,
      servicio4: `Demolition`,
      servicio5: `Underground utilities`,
      servicio6: `New construction residential and commercial`,
      servicio7: `Remodelation`,
      servicio8: `Plumbing`,

      tema3: `Industries we work with`,
      industria1: `Hotels`,
      industria2: `Hospitals`,
      industria3: `Schools`,
      industria4: `Commercial`,
      industria5: `Industrial`,

      tema4: `More services`,
    };
  }
}
