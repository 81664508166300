import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class PeticionesService {
  HOST: any = 'https://dnsgp.com/api/';
  // HOST: any = "https://dnsgp.com/api/";

  constructor(private toastr: ToastrService) {}

  async GetData(EndPoint) {
    let serviceRequest = await fetch(EndPoint);
    let data = await serviceRequest.json();
    return data;
  }

  async POST_DATA(data, endpoint, token?) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        auth: token,
      },
      body: JSON.stringify(data),
    };

    const request = await fetch(endpoint, options);

    const response = await request.json();
    
    return response;
  }
  
  idioma(idioma) {
    console.log('idioma_Servicio', idioma);
    if (idioma === 'ES') {
      $('#Cambiar_Espanol').trigger('click');
    } else if (idioma === 'EN') {
      $('#Cambiar_Ingles').trigger('click');
    }
  }
}
