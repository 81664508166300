import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dns-projects',
  templateUrl: './dns-projects.component.html',
  styleUrls: ['./dns-projects.component.css'],
})
export class DnsProjectsComponent implements OnInit {
  Data: any = {};
  Texto_Idioma: any[] = [];
  initialData = [
    {
      title: 'Terra Box - Los Mochis - SIN - MEXICO. ',
      project: 'Terra Box',
      year: 'Comming soon 2025',
      services:
        'Architecture, structural design, MEP Design, exterior renderings',
      imageUrl: "url('../../assets/Imagenes/projects/Tera_Mochis.jpeg')",
      category: 'Residential',
    },
    {
      title: 'Terra Box - Mazatlán - SIN - MEXICO. ',
      project: 'Terra Box',
      year: 'Comming soon 2025',
      services:
        'Architectural, Civil, Structural,MEP Design, Rendering.',
      imageUrl: "url('../../assets/Imagenes/projects/Tera_Mazatlan.jpeg')",
      category: 'Residential',
    },
    {
      title: 'Terra Box - Guadalajara - JAL - MEXICO. ',
      project: 'Terra Box',
      year: 'Comming soon 2025',
      services:
        'Architectural, Civil, Structural,MEP Design, Rendering.',
      imageUrl: "url('../../assets/Imagenes/projects/Tera_Guadalajara.jpeg')",
      category: 'Residential',
    },
    {
      title: 'Terra Box - Rosarito - BC - MEXICO. ',
      project: 'Terra Box',
      year: 'Comming soon 2025',
      services:
        'Architectural, Civil, Structural,MEP Design, Rendering.',
      imageUrl: "url('../../assets/Imagenes/projects/Tera_Rosarito.jpeg')",
      category: 'Residential',
    },
    {
      title: 'Terra Box - Landcaster - CA - USA. ',
      project: 'Terra Box',
      year: 'Comming soon 2025',
      services:
        'Architectural, Civil, Structural,MEP Design, Rendering.',
      imageUrl: "url('../../assets/Imagenes/projects/Tera_Landcaster.jpeg')",
      category: 'Residential',
    },
    {
      title: 'Holiday Inn Express & Suites - Anderson - CA - USA',
      project: 'Holiday Inn Express & Suites',
      franchise: 'IHG',
      year: 'Comming Soon 2025',
      services: 'Architectural,Civil,Structural,MEP,Fire and Safty, Landscape Design.  Rendering.',      
      imageUrl: "url('../../assets/Imagenes/projects/Holiday_Anderson.png')",
      category: 'Hospitality',
    },
    {
      title: 'Bee Apartments - Lodi - CA - USA',
      project: 'Bee Apartments',
      units: '8 residential apartments with mixed use floor',
      stories: '4 floors',
      year: 'Coming soon 2025',
      services:
        'Architecture, structural design, MEP Design, exterior renderings',
      imageUrl: "url('../../assets/Imagenes/projects/bee-apartments-lodi.jpg')",
      category: 'Residential',
    },
    {
      title: 'Staybridge Suites - Anderson - CA - USA',
      project: 'Staybridge Suites',
      franchise: 'IHG',
      year: 'Coming soon 2025',
      services:
        'Architecture, structural design, MEP Design, exterior renderings',
      imageUrl: "url('../../assets/Imagenes/projects/anderson7.png')",
      category: 'Hospitality',
    },
    {
      title: 'Atwell Suites By IHG - Las Vegas - NV - USA',
      project: 'Atwell Suites',
      franchise: 'IHG',
      year: 'Under Construction 2025',
      services: 'Electric low voltage, communication, surveillance system and audio system',      
      imageUrl: "url('../../assets/Imagenes/projects/atwell.jpeg')",
      category: 'Hospitality',
    },
    {
      title: 'La Quinta Inn - Tracy - CA - USA',
      project: 'La Quinta Inn',
      franchise: 'Wyndham Hotels',
      year: 'Coming soon 2024',
      services: 'Electrical and Fire alarm',
      imageUrl: "url('../../assets/Imagenes/projects/quinta-tracy.png')",
      category: 'Hospitality',
    },
    {
      title: 'Staybridge Suites - Manteca - CA - USA',
      project: 'Staybridge Suites',
      franchise: 'IHG',
      year: '2024',
      services: 'Electric low voltage, communication and surveillance system',
      imageUrl: "url('../../assets/Imagenes/projects/staybridge-suites-1.jpg')",
      category: 'Hospitality',
    },
    {
      title: 'Fairfield Inn & Suites - Livermore - CA - USA',
      project: 'Fairfield Inn & Suites',
      franchise: 'Marriott',
      year: '2024',
      services:
        'Electric low voltage, communication, surveillance system and audio system',
      imageUrl:
        "url('../../assets/Imagenes/livermore.jpg')",
      category: 'Hospitality',
    },
    {
      title: ' Gas Station - Rancho Murieta - CA - USA',
      project: 'Gas Station',
      year: '2024',
      services:
        'Survilliance system, Low Voltage, Networking, Audio System',
      imageUrl: "url('../../assets/Imagenes/projects/gas_murieta.png')",
      category: 'Commercial',
    },
    {
      title: 'HOME2 SUITES - American Canyon - CA - USA',
      project: 'HOME2 SUITES',
      franchise: 'Hilton',
      year: '2023',
      services: 'Electric low voltage and communication',
      imageUrl: "url('../../assets/Imagenes/projects/home2_1.jpg')",
      category: 'Hospitality',
    },
    {
      title: 'Fairfield Inn & Suites - Winnemucca - NV - USA',
      project: 'Fairfield Inn & Suites',
      franchise: 'Marriott',
      year: '2021',
      services:
        'Electric low voltage, communication, surveillance system and audio system',
      imageUrl:
        "url('../../assets/Imagenes/projects/fairfield-winnemucca.jpg')",
      category: 'Hospitality',
    },
    {
      title: 'King and Queen Cantina - Oxnard - CA - USA. ',
      year: '2021',
      services:
        'Survilliance system, Networking.',
      imageUrl: "url('../../assets/Imagenes/projects/tempo4.png')",
      category: 'Commercial',
    },
    {
      title: 'Mr. Tempo Corporate - Whittier - CA - USA. ',
      year: '2021',
      services:
        'Survilliance system, Networking.',
      imageUrl: "url('../../assets/Imagenes/projects/tempo5.png')",
      category: 'Commercial',
    },
    {
      title: 'Tempo Cantina - Brea - CA - USA. ',
      year: '2021',
      services:
        'Survilliance system, Networking.',
      imageUrl: "url('../../assets/Imagenes/projects/tempo1.png')",
      category: 'Commercial',
    },
    {
      title: 'Tempo Cantina - Anaheim - CA - USA. ',
      year: '2021',
      services:
        'Survilliance system, Networking.',
      imageUrl: "url('../../assets/Imagenes/projects/tempo2.png')",
      category: 'Commercial',
    },
    {
      title: 'King and Queen Cantina - Whittier - CA - USA. ',
      year: '2021',
      services:
        'Survilliance system, Networking.',
      imageUrl: "url('../../assets/Imagenes/projects/tempo3.png')",
      category: 'Commercial',
    },
    {
      title: 'HOME2 SUITES - Roswell - NM - USA',
      project: 'HOME2 SUITES',
      franchise: 'Hilton',
      year: '2020',
      services:
        'Electric low voltage, communication, surveillance system and audio system',
      imageUrl: "url('../../assets/Imagenes/projects/home2-roswell.png')",
      category: 'Hospitality',
    },
    {
      title: 'Staybridge Suites - Ames - IA - USA',
      project: 'Staybridge Suites',
      franchise: 'IHG',
      year: '2020',
      services:
        'Electric low voltage, communication, surveillance system and audio system',
      imageUrl: "url('../../assets/Imagenes/projects/staybridge-ames.jpg')",
      category: 'Hospitality',
    },
    {
      title: 'Best Western - Carlsbad - NV -USA',
      project: 'Best Western',
      franchise: 'Best Western',
      year: '2020',
      services: 'Underground utilities, communication and surveillance system',
      imageUrl:
        "url('../../assets/Imagenes/projects/best-western-carlsbad.jpg')",
      category: 'Hospitality',
    },
    {
      title: 'Holliday Inn - Liberal - KS - USA',
      project: 'Holliday Inn',
      franchise: 'IHG',
      year: '2018',
      services:
        'Electric low voltage, communication, surveillance system and audio system',
      imageUrl:
        "url('../../assets/Imagenes/projects/holliday-inn-liberal.jpg')",
      category: 'Hospitality',
    },
    {
      title: 'Fairfield Inn & Suites - Riverside - CA - USA',
      project: 'Fairfield Inn & Suites',
      franchise: 'Marriott',
      year: '2018',
      services: 'Electric low voltage and communication',
      imageUrl: "url('../../assets/Imagenes/projects/fairfield-riverside.png')",
      category: 'Hospitality',
    },
    {
      title: 'Newport Dunes Waterfront Resort - Newport Beach - CA - USA',
      project: 'Newport Dunes Waterfront Resort',
      year: '2017',
      services:
        'Acces Control, surveillance system, electrical Installation',
      imageUrl: "url('../../assets/Imagenes/projects/NewportDunes.png')",
      category: 'Commercial',
    },
    {
      title: 'SecurCare Self Storage - Montclair - CA - USA',
      project: 'SecurCare Self Storage',
      franchise: 'StorAmerica',
      year: '2017',
      services:
        'surveillance system y networking',
      imageUrl: "url('../../assets/Imagenes/projects/selfstorg9.png')",
      category: 'Commercial',
    },
    {
      title: 'Huntington Harbour Marina - Huntington Beach - CA - USA',
      project: 'Huntington Harbour Marina',
      year: '2017',
      services:
        'Acces Control, surveillance system, electrical Installation and communication',
      imageUrl: "url('../../assets/Imagenes/projects/marina2.png')",
      category: 'Commercial',
    },
    {
      title: 'Peters Landing Marina - Huntington Beach - CA - USA',
      project: 'Peters Landing Marina',
      year: '2017',
      services:
      'Acces Control, surveillance system, electrical Installation and communication',
      imageUrl: "url('../../assets/Imagenes/projects/marina3.png')",
      category: 'Commercial',
    },
    {
      title: 'Safe Harbor Anacapa Isle - Oxnard - CA - USA',
      project: 'Safe Harbor Anacapa Isle',
      year: '2017',
      services:
      'Access Control, surveillance system, electrical Installation and communication',
      imageUrl: "url('../../assets/Imagenes/projects/marina4.png')",
      category: 'Commercial',
    },
    {
      title: 'Kitsinian Jewelers - Valencia - CA - USA',
      project: 'Kitsinian Jewelers',
      year: '2017',
      services:
        'Communication, surveillance system and audio system',
      imageUrl: "url('../../assets/Imagenes/projects/joyeria1.png')",
      category: 'Commercial',
    },
    {
      title: 'Three Sisters Truck Stop - Fontana - CA - USA',
      project: 'Three Sisters Truck Stop',
      year: '2017',
      services:
        'Surveillance system, Access control, networking, phone system, electrical installation',
      imageUrl: "url('../../assets/Imagenes/projects/gasolinera1.png')",
      category: 'Commercial',
    },
    {
      title: 'I-10 Truck Stop - Rialto - CA - USA',
      project: 'I-10 Truck Stop',
      year: '2017',
      services:
      'Surveillance system, Access control, Surveillance system, phone system, electrical installation',
      imageUrl: "url('../../assets/Imagenes/projects/selfstorage1.png')",
      category: 'Commercial',
    },
    {
      title: 'Eco Thrift - San Bernardino - CA - USA',
      project: 'Eco Thrift',
      year: '2017',
      services:
        'Surveillance system, networking',
      imageUrl: "url('../../assets/Imagenes/projects/eco1.png')",
      category: 'Commercial',
    },
    {
      title: 'StorageMax Self Storage - Tustin - CA - USA',
      project: 'StorageMax Self Storage',
      franchise: 'StorAmerica',
      year: '2017',
      services:
        'surveillance system y networking',
      imageUrl: "url('../../assets/Imagenes/projects/selfstorage1.png')",
      category: 'Hotels',
    },
    {
      title: 'Power Self Storage - Los Angeles - CA - USA',
      project: 'Power Self Storage',
      franchise: 'StorAmerica',
      year: '2017',
      services:
        'surveillance system y networking',
      imageUrl: "url('../../assets/Imagenes/projects/selfstorage2.png')",
      category: 'Hotels',
    },
  /*   {
      title: 'SecurCare Self Storage - Los Angeles - CA - USA',
      project: 'SecurCare Self Storage',
      franchise: 'StorAmerica',
      year: '2017',
      services:
        'surveillance system y networking',
      imageUrl: "url('../../assets/Imagenes/projects/selfstorage3.png')",
      category: 'Commercial',
    }, */
    {
      title: 'StorAmerica Temecula - Temecula - CA - USA',
      project: 'StorAmerica Temecula',
      franchise: 'StorAmerica',
      year: '2017',
      services:
        'surveillance system y networking',
      imageUrl: "url('../../assets/Imagenes/projects/selfstorage4.png')",
      category: 'Hotels',
    },
    {
      title: 'SecurCare Self Storage - Ontorio - CA - USA',
      project: 'SecurCare Self Storage',
      franchise: 'StorAmerica',
      year: '2017',
      services:
        'surveillance system y networking',
      imageUrl: "url('../../assets/Imagenes/projects/selfstorage5.png')",
      category: 'Hotels',
    },
    {
      title: 'SecurCare Self Storage - Upland - CA - USA',
      project: 'SecurCare Self Storage',
      franchise: 'StorAmerica',
      year: '2017',
      services:
        'surveillance system y networking',
      imageUrl: "url('../../assets/Imagenes/projects/selfstorag6.png')",
      category: 'Hotels',
    },
    {
      title: 'Gateway Village - Tustin - CA - USA',
      project: 'Gateway Village',
      franchise: 'Gateway Village',
      year: '2017',
      services:
        'surveillance system y networking',
      imageUrl: "url('../../assets/Imagenes/projects/gatewayVillage.png')",
      category: 'Hotels',
    },
    {
      title: 'SecurCare Self Storage - Encinitas - CA - USA',
      project: 'SecurCare Self Storage',
      franchise: 'StorAmerica',
      year: '2017',
      services:
        'surveillance system y networking',
      imageUrl: "url('../../assets/Imagenes/projects/selfstorag7.png')",
      category: 'Hotels',
    },
    {
      title: 'SecurCare Self Storage - Oceanside - CA - USA',
      project: 'SecurCare Self Storage',
      franchise: 'StorAmerica',
      year: '2017',
      services:
        'surveillance system y networking',
      imageUrl: "url('../../assets/Imagenes/projects/selfstorag8.png')",
      category: 'Hotels',
    },
    {
      title: 'Pepsi Bottling Group - Riverside - CA - USA',
      project: 'Pepsi Bottling Group',
      year: '2017',
      services:
        'Networking, Survilliance system.',
      imageUrl: "url('../../assets/Imagenes/projects/pepsi.png')",
      category: 'Hotels',
    },
  ];

  dataCards = [];

  async ngOnInit() {
    window.scroll(0, 0);
    await this.cargarDatos();
  }

  filterData(category: string) {
    this.dataCards = this.initialData.filter(
      (item) => item.category === category
    );
  }

  async cargarDatos() {
    this.Cargar_Idioma();
    this.filterData('Hospitality');
  }

  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma'))
      .value;
    console.log('Oninit INICIO==>', btn_idioma);
    if (btn_idioma === 'ES') {
      this.Cambiar_Espanol();
    } else if (btn_idioma === 'EN') {
      this.Cambiar_Ingles();
    }
  }

  Cambiar_Espanol() {
    this.Data = {
      projectTitle: 'PROYECTOS',
      projectDescription: `El personal de Grupo DNS ha trabajado alrededor del mundo. Esta experiencia nos ha proporcionado una amplia gama de conocimientos en estándares de calidad, especificaciones y mejores prácticas internacionales para el diseño. Algunos de los proyectos mencionados en esta sección destacan el trabajo realizado por el personal del Grupo DNS para diferentes empresas.`,
    };
  }

  Cambiar_Ingles() {
    this.Data = {
      projectTitle: `PROJECTS`,
      projectDescription: `Grupo DNS staff have worked around the globe. This experience has provided us with a wide range of expertise in quality standards, specifications and international best practices for design. Some of the projects mentioned in this section highlight work done by Grupo DNS staff for different firms.`,
    };
  }
}
