import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../../app/peticiones.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dns-inicio-v2',
  templateUrl: './dns-inicio-v2.component.html',
  styleUrls: ['./dns-inicio-v2.component.css'],
})
export class DnsInicioV2Component implements OnInit {
  Data: any = {};
  Texto_Idioma: any[] = [];

  constructor(
    private _servicio: PeticionesService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    window.scroll(0, 0);
    await this.cargarDatos();
  }

  async cargarDatos() {
    this.Cargar_Idioma();
  }

  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma'))
      .value;
    console.log('Oninit INICIO==>', btn_idioma);
    if (btn_idioma === 'ES') {
      this.Cambiar_Espanol();
    } else if (btn_idioma === 'EN') {
      this.Cambiar_Ingles();
    }
  }

  Cambiar_Espanol() {
    this.Data = {
      videoTitle: 'CONSTRUYENDO HACIA EL FUTURO',
      titleAboutUs: 'CONSTRUYENDO GRANDES ASOCIACIONES',
      descriptionAboutUs: `Grupo Dns (Dns Gp) es una empresa de rápido crecimiento que tiene como objetivo revolucionar la industria de la construcción con nuevas innovaciones y desarrollo tecnológico, convirtiendo conceptos en realidad. Entendemos la importancia de su proyecto. Por esa razón, nuestro equipo profesional está comprometido a garantizar que sus proyectos se entreguen a tiempo a los precios más asequibles y competitivos del mercado.`,
      additionalData:'Estructuras construidas en todo Estados Unidos y Mexico.',
      additionalData2: 'Construí relaciones con empresas y marcas únicas.',
      additionalData3: 'Horas de experiencia en la construcción de proyectos.',
      serviceTitle: 'SERVICIOS',
      serviceDescription: `Como contratista general profesional, Grupo DNS se especializa en todo.
        desde servicios de preconstrucción y planificación de proyectos hasta diseño-construcción y
        servicios de gestión de la construcción. Nuestro equipo de profesionales capacitados proporciona
        una gama completa de servicios para que puedas completar la reforma de tu edificio,
        mejora del inquilino o proyecto de construcción desde cero a tiempo y dentro del presupuesto.`,
      generalContractorTitle: 'CONSTRUCCION',
      generalContractorDescription:
        'Nos especializamos en construcción desde cero, para residencial, comercial. Ofreciendo servicios de Contratación General e Ingeniería.',
      securityTitle: 'SEGURIDAD',
      securityDescription: `Nos especializamos en sistemas de vigilancia, control de acceso, intrusión, fibra óptica, cableado estructurado, redes, sistemas de audio, Iluminación y electricidad para uso comercial y residencial.`,
      telecomTitle: 'TELECOMUNICACIONES',
      telecomDescription:
        'Nos especializamos en cableado estructurado, data centers, tendido de fibra óptica, implementación de redes wifi, contamos con certificaciones Pandui, Ubiquiti y Meraki.',
      contactTitle:
        '¿Listo para comenzar su proyecto de construcción comercial?',
      buttonServices: 'Ver servicios',
      buttonProjects: 'Ver proyectos',
      buttonContact: 'Contacto',
    };
  }

  Cambiar_Ingles() {
    this.Data = {
      videoTitle: `BUILDING TOWARDS THE FUTURE`,
      titleAboutUs: 'BUILDING GREAT PARTNERSHIPS',
      descriptionAboutUs: `Grupo Dns (Dns Gp) is a fast-growing company that aims to revolutionize the construction industry with new innovations and technology development, turning concepts into reality. We understand the importance of your project. For that reason, our professional team is committed to ensuring that your projects are delivered on time at the most affordable and competitive prices in the market.`,
      additionalData: 'Structures Built Across the US and Mexico.',
      additionalData2: 'Built Relationships with Unique Companies and Brands.',
      additionalData3: 'Hours of Project Building Experience.',
      serviceTitle: 'SERVICES',
      serviceDescription: `As a professional general contractor, Grupo DNS specializes in everything
       from pre-construction and project planning services to design-build and
       construction management services. Our team of skilled professionals provides
       a full range of services so you can complete your building renovation,
       tenant improvement, or ground-up construction project on time and on budget.`,
      generalContractorTitle: 'GENERAL CONTRACTOR',
      generalContractorDescription:
        'We specialize in ground up construction, for residential, comercial. Offering services for General Contracting and Engineering.',
      securityTitle: 'SECURITY',
      securityDescription: `We specialize in surveillance systems, access control, intrusion, fiber optics, structured cabling, networks, audio systems, lighting and electrical for commercial and residential.`,
      telecomTitle: 'TELECOMUNICATION',
      telecomDescription:
        'We specialize in structured cabling, data centers, fiber optic laying, implementation of Wi-Fi networks, we have Pandui, Ubiquiti and Meraki certifications.',
      contactTitle: 'Ready to start your commercial construction project?',
      buttonServices: 'View Services',
      buttonProjects: 'View Projects',
      buttonContact: 'Contact',
    };
  }
}
