import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dns-contacto',
  templateUrl: './dns-contacto.component.html',
  styleUrls: ['./dns-contacto.component.css']
})
export class DnsContactoComponent implements OnInit {
  Data: any = {};

  constructor() { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.Cargar_Idioma();
  }


  position = {
    lat: 25.7667,
    lng: -108.9667
  };

  center_mapa = {
    lat: 29.1026,
    lng: -110.97732
  };

  position2 = {
    lat: 34.0194,
    lng: -118.411
  };


  label = {
    color: 'red',
    text: 'Marcador'
  }


  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma')).value;
    console.log("Oninit INICIO==>", btn_idioma);
    if (btn_idioma === "ES") {
      this.Cambiar_Espanol();
    } else if (btn_idioma === "EN") {
      this.Cambiar_Ingles();
    }
  }

  Cambiar_Espanol() {
    this.Data = {
      titulo: "Contacto",
      subtitulo1: "Para dudas y/o requerimientos de información al público, favor de consultar:",
      celular: "Celular",
      correo: "Correo",
      ubicacion: "Ubicacion"
    }
  }

  Cambiar_Ingles() {
    this.Data = {
      titulo: "Contact",
      subtitulo1: "For questions and / or information requirements to the public, please consult:",
      celular: "Phone",
      correo: "Mail",
      ubicacion: "Location"
    }
  }



}
