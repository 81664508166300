import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../../app/peticiones.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dns-telecom',
  templateUrl: './dns-telecom.component.html',
  styleUrls: ['./dns-telecom.component.css'],
})
export class DnsTelecomComponent implements OnInit {
  Data: any = {};
  Texto_Idioma: any[] = [];

  constructor(
    private _servicio: PeticionesService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  async ngOnInit() {
    window.scroll(0, 0);
    await this.cargarDatos();
  }

  async cargarDatos() {
    this.Cargar_Idioma();
  }

  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma'))
      .value;
    console.log('Oninit INICIO==>', btn_idioma);
    if (btn_idioma === 'ES') {
      this.Cambiar_Espanol();
    } else if (btn_idioma === 'EN') {
      this.Cambiar_Ingles();
    }
  }

  Cambiar_Espanol() {
    this.Data = {
      tema1: `¿Qué ofrecemos?`,

      descripcion1: `Nos especializamos en cableado estructurado, centros de datos, fibra óptica, redes Wi-Fi, somos instaladores certificados en Pandui, Ubiquite y Meraki.`,

      tema2: `Servicios`,

      servicio1: `Cableado estructurado`,
      servicio2: `Fibra óptica`,
      servicio3: `Diseño de redes`,
      servicio4: `Monitoreo de redes`,
      servicio5: `Data Centers`,
      servicio6: `Telefonía  VOIP`,
      servicio7: `Radiocomunicación`,

      tema3: `Industrias que trabajamos`,
      industria1: `Hoteles`,
      industria2: `Hospitales`,
      industria3: `Escuelas`,
      industria4: `Comercial`,
      industria5: `Industrial`,

      tema4: `Mas servicios`,
    };
  }

  Cambiar_Ingles() {
    this.Data = {
      tema1: `What we offer?`,

      descripcion1: `We specialize in structured cabling, data centers, fiber optic, Wi-Fi networks, we are certified installers in Pandui, Ubiquite and Meraki.`,

      tema2: `Services`,

      servicio1: `Structured cabling`,
      servicio2: `Optical fiber`,
      servicio3: `Network design`,
      servicio4: `Network monitoring`,
      servicio5: `Data Centers`,
      servicio6: `VOIP Telephony`,
      servicio7: `Radiocomunication`,

      tema3: `Industries we work with`,
      industria1: `Hotels`,
      industria2: `Hospitals`,
      industria3: `Schools`,
      industria4: `Commercial`,
      industria5: `Industrial`,

      tema4: `More services`,
    };
  }
}
