import { RouterModule, Routes } from '@angular/router';


import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';



//servicios
import { PeticionesService } from '../app/peticiones.service';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PanelControlComponent } from './panel-control/panel-control.component';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DnsConstructionComponent } from './dns-construction/dns-construction.component';
import { DnsSecurityComponent } from './dns-security/dns-security.component';
import { DnsTelecomComponent } from './dns-telecom/dns-telecom.component';
import { DnsAcercadeComponent } from './dns-acercade/dns-acercade.component';
import { DnsContactoComponent } from './dns-contacto/dns-contacto.component';
import { DnsInicioComponent } from './dns-inicio/dns-inicio.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GaleriaComponent } from './galeria/galeria.component';
import { CertificacionesComponent } from './certificaciones/certificaciones.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ClientesFotosComponent } from './clientes-fotos/clientes-fotos.component';
import { DnsInicioV2Component } from './dns-inicio-v2/dns-inicio-v2.component';
import { DnsProjectsComponent } from './dns-projects/dns-projects.component';






const routes: Routes = [
 

  { path: '', redirectTo: '/dns/inicio', pathMatch: 'full' },
  // { path: '**', redirectTo: '/dns/inicio', pathMatch: 'full' },

  {
    path: 'dns', component: PanelControlComponent,
    children: [
      // { path: 'inicio', component: DnsInicioComponent },
      { path: 'inicio', component: DnsInicioV2Component },
      { path: 'nosotros', component: DnsAcercadeComponent },
      { path: 'contacto', component: DnsContactoComponent },
      { path: 'projects', component: DnsProjectsComponent },
      { path: 'dnssecurity', component: DnsSecurityComponent },
      { path: 'dnstelecom', component: DnsTelecomComponent },
      { path: 'dnsconstruction', component: DnsConstructionComponent },
      { path: 'portafolio', component: ClientesFotosComponent },
      { path: 'galeria', component: GaleriaComponent },
      { path: 'certificaciones', component: CertificacionesComponent },
      { path: 'clientes', component: ClientesComponent },
    ]
  },
];

@NgModule({
  declarations: [
    AppComponent,
    PanelControlComponent,
    DnsConstructionComponent,
    DnsSecurityComponent,
    DnsTelecomComponent,
    DnsAcercadeComponent,
    DnsContactoComponent,
    DnsInicioComponent,
    GaleriaComponent,
    CertificacionesComponent,
    ClientesComponent,
    ClientesFotosComponent,
    DnsInicioV2Component,
    DnsProjectsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    RouterModule.forRoot(routes),
    MatFormFieldModule,
    ToastrModule.forRoot(),
    FormsModule,
    GoogleMapsModule
  ],
  providers: [PeticionesService],
  bootstrap: [AppComponent]
})
export class AppModule { }
