<div [ngSwitch]="tab2">
  <div id="container_portfolio" *ngSwitchCase="1">
    <div
      id="imagen_servicio2"
      class="animate__animated animate__slideInLeft imagen_servicio"
    >
      <p id="text_image">{{ Data.titulo }}</p>
    </div>

    <div id="wrapper_text">
      <div id="div_titulo">
        <p id="p_tit">{{ Data.titulo_prin }}</p>
      </div>

       <div>
                <p id="p_sub">{{Data.subtitulo}}</p>
            </div>

            <div>
                <p class="p_sub"><i style="margin-right: 1vw;" class="fas fa-dot-circle"></i>Holiday Inn Express - KS
                    2000</p>
            </div>

            <div>
                <p class="p_sub"><i style="margin-right: 1vw;" class="fas fa-dot-circle"></i>Holiday Inn Express - KS
                    2000</p>
            </div>
            <div>
                <p class="p_sub"><i style="margin-right: 1vw;" class="fas fa-dot-circle"></i>Holiday Inn Express - KS
                    2000</p>
            </div>
            <div>
                <p class="p_sub"><i style="margin-right: 1vw;" class="fas fa-dot-circle"></i>Holiday Inn Express - KS
                    2000</p>
            </div>
            <div>
                <p class="p_sub"><i style="margin-right: 1vw;" class="fas fa-dot-circle"></i>Holiday Inn Express - KS
                    2000</p>
            </div>
            <div>
                <p class="p_sub"><i style="margin-right: 1vw;" class="fas fa-dot-circle"></i>Holiday Inn Express - KS
                    2000</p>
            </div>
            <div>
                <p class="p_sub"><i style="margin-right: 1vw;" class="fas fa-dot-circle"></i>Holiday Inn Express - KS
                    2000</p>
            </div>
            <div>
                <p class="p_sub"><i style="margin-right: 1vw;" class="fas fa-dot-circle"></i>Holiday Inn Express - KS
                    2000</p>
            </div>

            <div>
                <p id="p_sub2">{{Data.subtitulo2}}</p>
            </div> 
    </div>

    <div id="div_all_items">
      <div *ngFor="let Item of Filter_Items_trabajos; index as i">
        <div id="item_portfolio" *ngIf="i % 2 == 0">
          <div
            id="imagen_item"
            [ngStyle]="{
              'background-image':
                'url(https://dnsgp.com/api/images/' + Item.imagenes[0] + ')'
            }"
          ></div>

          <div id="text_item" class="imagen_d">
            <div id="wrapper">
              <div id="div_texto" [ngSwitch]="tab">
                <p *ngSwitchCase="1" id="p_text">
                  {{ Item.name_spanish }}
                </p>
                <p *ngSwitchCase="2" id="p_text">
                  {{ Item.name_english }}
                </p>
              </div>

              <div>
                <p id="p_ver_galeria" (click)="cambiar_pantalla(Item.id)">
                  {{ Data.galeria }}<i class="fas fa-arrow-circle-right"></i>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="item_portfolio" *ngIf="i % 2 == 1">
          <div id="text_item">
            <div id="wrapper">
              <div id="div_texto" [ngSwitch]="tab">
                <p *ngSwitchCase="1" id="p_text">
                  {{ Item.name_spanish }}
                </p>
                <p *ngSwitchCase="2" id="p_text">
                  {{ Item.name_english }}
                </p>
              </div>

              <div>
                <p id="p_ver_galeria" (click)="cambiar_pantalla(Item.id)">
                  {{ Data.galeria }}<i class="fas fa-arrow-circle-right"></i>
                </p>
              </div>
            </div>
          </div>

          <div
            id="imagen_item"
            [ngStyle]="{
              'background-image':
                'url(https://dnsgp.com/api/images/' + Item.imagenes[0] + ')'
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div id="container_detalles_portfolio" *ngSwitchCase="2">
    <div class="animate__animated animate__slideInLeft imagen_servicio">
      <p id="text_image">{{ Data.titulo }}</p>
    </div>
    <div (click)="imagen_back()" id="div_back">
      <i id="back" class="fas fa-long-arrow-alt-left"></i>
      <p id="p_regresar">Regresar</p>
    </div>

    <div id="imagenes_wrapper">
      <div
        id="foto_individual"
        *ngFor="let Item of Items_panel_carrousel; index as i"
        [ngStyle]="{
          'background-image':
            'url(https://dnsgp.com/api/images/' + Item.url + ')'
        }"
      ></div>
    </div>
  </div>

  <select style="display: none" id="select_inicio">
    <option id="Cambiar_Espanol" (click)="Cambiar_Espanol()">ES</option>
    <option id="Cambiar_Ingles" (click)="Cambiar_Ingles()">EN</option>
  </select>
</div>
