<section id="container_general">
  <div id="panel_control">
    <header id="header">
      <div id="header_pc">
        <div id="hamburger">
          <i
            (click)="extendermenu()"
            id="hamburger_menu"
            class="fas fa-bars"
          ></i>
        </div>

        <div id="logo">
          <div id="logo-image"></div>
          <div id="container-text">
            <p [routerLink]="['dnstelecom']" id="logo-text-telecom">Telecom</p>
            <p [routerLink]="['dnssecurity']" id="logo-text-security">
              Security
            </p>
            <p [routerLink]="['dnsconstruction']" id="logo-text-construction">
              Construction
            </p>
          </div>
        </div>

        <div id="links">
          <div class="dropdown">
            <button class="dropbtn" [routerLink]="['inicio']">
              {{ Datas.inicio }}
            </button>
            <div class="dropdown-content"></div>
          </div>

          <!-- <div class="dropdown">
                        <button class="dropbtn">{{Datas.servicios}}<i style="margin-left: 3px;"
                                class="fas fa-angle-down"></i></button>
                        <div class="dropdown-content">
                            <a href="#" [routerLink]="['dnstelecom']">{{Datas.telecomunicaciones}}</a>
                            <a href="#" [routerLink]="['dnssecurity']">{{Datas.seguridad}}</a>
                            <a href="#" [routerLink]="['dnsconstruction']">{{Datas.construccion}}</a>
                        </div>
                    </div> -->

          <div class="dropdown">
            <button class="dropbtn" [routerLink]="['dnsconstruction']">
              {{ Datas.construccion }}
            </button>
            <div class="dropdown-content"></div>
          </div>

          <div class="dropdown">
            <button class="dropbtn" [routerLink]="['dnssecurity']">
              {{ Datas.seguridad }}
            </button>
            <div class="dropdown-content"></div>
          </div>

          <div class="dropdown">
            <button class="dropbtn" [routerLink]="['dnstelecom']">
              {{ Datas.telecomunicaciones }}
            </button>
            <div class="dropdown-content"></div>
          </div>

          <div class="dropdown">
            <button class="dropbtn" [routerLink]="['contacto']">
              {{ Datas.contacto }}
            </button>
            <div class="dropdown-content"></div>
          </div>

          <div class="dropdown">
            <button class="dropbtn" [routerLink]="['projects']">
              Projects
            </button>
            <div class="dropdown-content"></div>
          </div>
        </div>

        <div id="idioma">
          <select id="btn_idioma" (change)="Actualizar_idioma()">
            <option id="opc2">EN</option>
            <option id="opc1">ES</option>
          </select>
        </div>
      </div>
    </header>

    <nav id="nav_left">
      <button
        id="btn_ocultar_menu"
        (click)="ocultar_menu()"
        class="btn btn-ligth"
      >
        x
      </button>

      <div id="div_nav">
        <div [routerLink]="['inicio']" (click)="ocultar_menu()">
          <a>{{ Datas.inicio }}</a>
        </div>

        <div [routerLink]="['dnssecurity']" (click)="ocultar_menu()">
          <a>DNS Security</a>
        </div>

        <div [routerLink]="['dnstelecom']" (click)="ocultar_menu()">
          <a>DNS Telecom</a>
        </div>

        <div [routerLink]="['dnsconstruction']" (click)="ocultar_menu()">
          <a>DNS Construction</a>
        </div>

        <div [routerLink]="['contacto']" (click)="ocultar_menu()">
          <a>{{ Datas.contacto }}</a>
        </div>

        <div [routerLink]="['projects']" (click)="ocultar_menu()">
          <a>{{ Datas.projects }}</a>
        </div>
      </div>
    </nav>

    <section id="section2">
      <router-outlet id="router-outlet"></router-outlet>
    </section>

    <footer id="footer">
      <div id="logo_footer"></div>

      <div id="link_footer">
        <div [routerLink]="['inicio']">
          <a>{{ Datas.inicio }}</a>
        </div>

        <div>
          <a [routerLink]="['dnssecurity']">DNS Security</a>
        </div>

        <div>
          <a [routerLink]="['dnstelecom']">DNS Telecom</a>
        </div>

        <div>
          <a [routerLink]="['dnsconstruction']">DNS Construction</a>
        </div>

        <div>
          <a [routerLink]="['contacto']">{{ Datas.contacto }}</a>
        </div>

        <div>
          <a [routerLink]="['projects']">{{ Datas.projects }}</a>
        </div>
      </div>

      <label id="copy">License: CA C-10,B,A. #1118308 DNSGP LLC</label>
      <label id="copy">©DNS Group 2020.</label>
    </footer>
  </div>
</section>
