<p id="titulo">{{Data.titulo}}</p>
<p id="subtitulo">{{Data.subtitulo}}</p>


<div id="div_card_cliente">

    <div [ngSwitch]="tab" id="card_cliente" *ngFor="let Item of Filter_Items_clientes">
        <div class="imagen_clientes" id="imagen_cliente1"
            [ngStyle]="{'background-image': 'url(https://dnsgp.com/api/images/' + Item.imagenes[0] + ')'}">

        </div>

        <p *ngSwitchCase="1" id="titulo_cliente">{{Item.name_spanish}}</p>

        <p *ngSwitchCase="2" id="titulo_cliente">{{Item.name_english}}</p>

    </div>



    <div id="card_cliente">
        <div class="imagen_clientes" id="imagen_cliente1">

        </div>

        <p id="titulo_cliente">INVERIA</p>

    </div>


    <div id="card_cliente">
        <div class="imagen_clientes" id="imagen_cliente2">

        </div>

        <p id="titulo_cliente">VANNKI</p>

    </div>


    <div id="card_cliente">
        <div class="imagen_clientes" id="imagen_cliente3">

        </div>

        <p id="titulo_cliente">DESTINOS GOLF</p>

    </div>

    <div id="card_cliente">
        <div class="imagen_clientes" id="imagen_cliente4">

        </div>

        <p id="titulo_cliente">PURO TALENTO</p>

    </div>


    <div id="card_cliente">
        <div class="imagen_clientes" id="imagen_cliente5">

        </div>

        <p id="titulo_cliente">MARKMEUP</p>

    </div>

    <div id="card_cliente">
        <div class="imagen_clientes" id="imagen_cliente6">

        </div>

        <p id="titulo_cliente">COLORES 33</p>

    </div>

    <div id="card_cliente">
        <div class="imagen_clientes" id="imagen_cliente7">

        </div>

        <p id="titulo_cliente">LEADMAN CAPITAL</p>

    </div>

    <div id="card_cliente">
        <div class="imagen_clientes" id="imagen_cliente8">

        </div>

        <p id="titulo_cliente">MADARIAGA TAX&LEGAL </p>

    </div> 

</div>






<select style="display:none;" id="select_inicio">
    <option id="Cambiar_Espanol" (click)="Cambiar_Espanol()">ES</option>
    <option id="Cambiar_Ingles" (click)="Cambiar_Ingles()">EN</option>
</select>