<div id="imagen_servicio" class="animate__animated animate__slideInLeft">
  <p id="text_image">{{ Data.projectTitle }}</p>
</div>

<div class="container-abount-us">
  <h3 class="title-about-us">{{ Data.projectTitle }}</h3>

  <div class="container-line"></div>

  <p id="project-description">
    {{ Data.projectDescription }}
  </p>

  <br>
  <br>

  <div class="container-radio-button">
    <div class="btn-group" id="div-radio-button" role="group" aria-label="Basic radio toggle button group">
      <input  (click)="filterData('Hospitality')"  type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked>
      <label class="btn btn-outline-primary" for="btnradio2">Hospitality</label>
      
      <input  (click)="filterData('Commercial')"  type="radio" class="btn-check" name="btnradio" id="btnradio4" autocomplete="off">
      <label class="btn btn-outline-primary" for="btnradio4">Commercial</label>

      <input (click)="filterData('Hotels')" type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off">
      <label class="btn btn-outline-primary" for="btnradio3">Industrial</label>

      <input (click)="filterData('Residential')" type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off">
      <label class="btn btn-outline-primary" for="btnradio1">Residential</label>
    </div>
  </div>
 
</div>



<div class="cointainer-cards">

  <div class="project-card" *ngFor="let item of dataCards">
    <div class="project-image" [ngStyle]="{'background-image': item.imageUrl}"></div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed"
           type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
           {{item.title}}
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <p *ngIf="item.project" class="card-title">Project</p>
            <p>{{item.project}}</p>
            <p *ngIf="item.units" class="card-title">Units</p>
            <p>{{item.units}}</p>
            <p *ngIf="item.units" class="card-title">Stories</p>
            <p>{{item.stories}}</p>
            <p *ngIf="item.franchise" class="card-title">FRANCHISE</p>
            <p>{{item.franchise}}</p>
            <p class="card-title">YEAR</p>
            <p>{{item.year}}</p>
            <p class="card-title">SERVICES</p>
            <p>{{item.services}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<select style="display: none" id="select_inicio">
  <option id="Cambiar_Espanol" (click)="Cambiar_Espanol()">ES</option>
  <option id="Cambiar_Ingles" (click)="Cambiar_Ingles()">EN</option>
</select>
