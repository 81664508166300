<div id="container_foto_inicio">
    <div class="animate__animated animate__slideInLeft" id="foto_incio">
    </div>
    <p class="animate__animated animate__slideInLeft" id="text_image">{{Data.titulo_inicio}}</p>
    <div [routerLink]="['/dns/dnstelecom']">
        <button class="animate__animated animate__slideInLeft">{{Data.titulo_boton}}</button>
    </div>
</div>


<div [routerLink]="['/dns/dnssecurity']" id="container_foto_security">
    <div class="animate__animated animate__slideInLeft" id="foto_security">
    </div>
    <p class="animate__animated animate__slideInLeft" id="text_image2">DNS Security </p>
</div>


<div [routerLink]="['/dns/dnsconstruction']" id="container_foto_construction">
    <div class="animate__animated animate__slideInLeft" id="foto_construction">
    </div>
    <p class="animate__animated animate__slideInLeft" id="text_image3">DNS Construction </p>
</div>


<div [routerLink]="['/dns/dnstelecom']" id="container_foto_telecom">
    <div class="animate__animated animate__slideInLeft" id="foto_telecom">
    </div>
    <p class="animate__animated animate__slideInLeft" id="text_image2">DNS Telecom </p>
</div>

<div [routerLink]="['/dns/nosotros']" id="container_foto_nosotros">
    <div class="animate__animated animate__slideInLeft" id="foto_nosotros">
    </div>
    <p class="animate__animated animate__slideInLeft" id="text_image2">{{Data.nosotros}}</p>
</div>

<div [routerLink]="['/dns/contacto']" id="container_foto_contacto">
    <div class="animate__animated animate__slideInLeft" id="foto_contacto">
    </div>
    <p class="animate__animated animate__slideInLeft" id="text_image4">{{Data.contactanos}}</p>
</div>

<!-- <select id="select_inicio"> -->
<!-- <option id="Cambiar_Espanol" (click)="Cambiar_Espanol()">ES</option> -->
<!-- <option id="Cambiar_Ingles" (click)="Cambiar_Ingles()">EN</option> -->
<!-- </select> -->

<button style="display: none;" id="Cambiar_Espanol" (click)="Cambiar_Espanol()"> Español </button>
<button  style="display: none;" id="Cambiar_Ingles" (click)="Cambiar_Ingles()"> Ingles </button>