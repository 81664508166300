import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../../app/peticiones.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-panel-control',
  templateUrl: './panel-control.component.html',
  styleUrls: ['./panel-control.component.css']
})
export class PanelControlComponent implements OnInit {

  constructor(
    private _servicio: PeticionesService,
  ) { }

  ngOnInit(): void {
  }
 
  extendermenu() {
    if (screen.width > 720) {
      $('#nav_left').removeClass("animate__animated animate__fadeInRight").addClass("animate__animated animate__fadeInLeft");
      document.getElementById("nav_left").style.display = "block";
      document.getElementById("nav_left").style.width = "15.5%";
    } else if (screen.width < 720) {
      $('#nav_left').removeClass("animate__animated animate__fadeInRight").addClass("animate__animated animate__fadeInLeft");
      document.getElementById("nav_left").style.display = "block";
      document.getElementById("nav_left").style.width = "80%";
    }
  }


  ocultar_menu() {
    // $('#nav_left').removeClass("animate__animated animate__fadeInLeft").addClass("animate__animated animate__fadeInRight");
    document.getElementById("nav_left").style.width = "0%";
    document.getElementById("nav_left").style.display = "none";
  }

  Datas = {
    inicio: "Home",
    nosotros: "About Us",
    contacto: "Contact Us",
    galeria: "Gallery",
    portafolio: "Portfolio",
    servicios: "Services",
    empresa: "Company",
    certificaciones: "Certifications",
    telecomunicaciones: "Telecommunications",
    seguridad: "Security",
    construccion: "General Contractor",
    clientes: "Customers",
    projects: "Projects"
  }

  Actualizar_idioma() {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma')).value;
    console.log("Idioma Change==>", btn_idioma);
    this._servicio.idioma(btn_idioma)

    if (btn_idioma === "ES") {
      this.Datas = {
        inicio: "Inicio",
        nosotros: "Acerca de Nosotros",
        contacto: "Contacto",
        galeria: "Galeria",
        portafolio: "Portafolio",
        servicios: "Servicios",
        empresa: "Empresa",
        certificaciones: "Certificaciones",
        telecomunicaciones: "Telecomunicaciones",
        seguridad: "Seguridad",
        construccion: "Construcción",
        clientes: "Clientes",
        projects: "Proyectos"
      }
    } else if (btn_idioma === "EN") {
      this.Datas = {
        inicio: "Home",
        nosotros: "About Us",
        contacto: "Contact Us",
        galeria: "Gallery",
        portafolio: "Portfolio",
        servicios: "Services",
        empresa: "Company",
        certificaciones: "Certifications",
        telecomunicaciones: "Telecommunications",
        seguridad: "Security",
        construccion: "General Contractor",
        clientes: "Customers",
        projects: "Projects"
      }
    }
  }






}

