<div class="container-video">
  <video
    autoplay
    loop
    muted
    id="video-background"
    src="../../assets/videos/2.mp4"
  >
    <source
      src="../../assets/videos/2.mp4"
      type="video/mp4"
    />
    Tu navegador no soporta videos HTML5.
  </video>
  <div class="filtro"></div>


  <p>{{ Data.videoTitle }}</p>
</div>

<div class="container-abount-us">
  <h3 class="title-about-us">{{ Data.titleAboutUs }}</h3>

  <div class="container-line"></div>

  <p>
    {{ Data.descriptionAboutUs }}
  </p>

  <div class="container-additional-data">
    <div>
      <h2>20+</h2>
      <p>{{ Data.additionalData }}</p>
    </div>

    <div>
      <h2>50+</h2>
      <p>{{ Data.additionalData2 }}</p>
    </div>

    <div>
      <h2>10000+</h2>
      <p>{{ Data.additionalData3 }}</p>
    </div>
  </div>
</div>

<div class="container-services">
  <h3 class="title-about-us">{{ Data.serviceTitle }}</h3>

  <div class="container-line"></div>

  <p>
    {{ Data.serviceDescription }}
  </p>

  <div class="service-card">
    <div class="service-image" id="hotel-image"></div>

    <div class="service-info">
      <h3>{{ Data.generalContractorTitle }}</h3>
      <p>
        {{ Data.generalContractorDescription }}
      </p>
      <button
        [routerLink]="['/dns/dnsconstruction']"
        type="button"
        class="btn btn-success"
      >
        {{ Data.buttonServices }}
      </button>
    </div>
  </div>

  <div class="service-card">
    <div class="service-info">
      <h3>{{ Data.securityTitle }}</h3>
      <p>
        {{ Data.securityDescription }}
      </p>
      <button
        [routerLink]="['/dns/dnssecurity']"
        type="button"
        class="btn btn-danger"
      >
        {{ Data.buttonServices }}
      </button>
    </div>

    <div class="service-image" id="security-cam-image"></div>
  </div>

  <div class="service-card">
    <div class="service-image" id="telecomunication-image"></div>

    <div class="service-info">
      <h3>{{ Data.telecomTitle }}</h3>
      <p>
        {{ Data.telecomDescription }}
      </p>
      <button
        [routerLink]="['/dns/dnstelecom']"
        type="button"
        class="btn btn-info"
      >
        {{ Data.buttonServices }}
      </button>
    </div>
  </div>
</div>

<div class="container-projects">
  <div id="carouselExampleCaptions" class="carousel slide">
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <video
          autoplay
          loop
          muted
          id="video-background"
          src="../../assets/videos/construction.mov"
        >
          <source
            src="../../assets/videos/construction.mov"
            type="video/mp4"
          />
          Tu navegador no soporta videos HTML5.
        </video>
        <div class="filtro"></div>

        <div class="carousel-caption d-none d-md-block">
          <h1 class="title-about-us" id="title-about-us_construction">{{ Data.generalContractorTitle }}</h1>
          <button
            [routerLink]="['/dns/projects']"
            type="button"
            class="btn btn-success"
          >
            {{ Data.buttonProjects }}
          </button>
        </div>
      </div>
      <div class="carousel-item">
        <video
          autoplay
          loop
          muted
          id="video-background"
          src="../../assets/videos/security_recortado.mp4"
        >
          <source
            src="../../assets/videos/security_recortado.mp4"
            type="video/mp4"
          />
          Tu navegador no soporta videos HTML5.
        </video>
        <div class="carousel-caption d-none d-md-block">
          <h1 class="title-about-us">{{ Data.securityTitle }}</h1>
          <button
            [routerLink]="['/dns/projects']"
            type="button"
            class="btn btn-danger"
          >
            {{ Data.buttonProjects }}
          </button>
        </div>
      </div>

      <div class="carousel-item">
        <video
          autoplay
          loop
          muted
          id="video-background"
          src="../../assets/videos/telecom_video.mp4"
        >
          <source
            src="../../assets/videos/telecom_video.mp4"
            type="video/mp4"
          />
          Tu navegador no soporta videos HTML5.
        </video>
        <div class="carousel-caption d-none d-md-block">
          <h1 class="title-about-us">{{ Data.telecomTitle}}</h1>
          <button
            [routerLink]="['/dns/telecom']"
            type="button"
            class="btn btn-info"
          >
            {{ Data.buttonProjects }}
          </button>
        </div>
      </div>
    </div>
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExampleCaptions"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>

<div class="container-contact">
  <h1 class="title-about-us">{{ Data.contactTitle }}</h1>
  <button [routerLink]="['/dns/contacto']">{{ Data.buttonContact }}</button>
</div>

<select style="display: none" id="select_inicio">
  <option id="Cambiar_Espanol" (click)="Cambiar_Espanol()">ES</option>
  <option id="Cambiar_Ingles" (click)="Cambiar_Ingles()">EN</option>
</select>
