import { Component, OnInit } from '@angular/core';
import { PeticionesService } from '../../app/peticiones.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dns-inicio',
  templateUrl: './dns-inicio.component.html',
  styleUrls: ['./dns-inicio.component.css'],
})
export class DnsInicioComponent implements OnInit {
  Data: any = {};
  Texto_Idioma: any[] = [];
  Response: any[] = [];

  constructor(
    private _servicio: PeticionesService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    window.scroll(0, 0);
    await this.cargarDatos();
  }

  async cargarDatos() {
    this.Cargar_Idioma();
  }

  Cargar_Idioma(): any {
    let btn_idioma = (<HTMLInputElement>document.getElementById('btn_idioma'))
      .value;
    console.log('Oninit INICIO==>', btn_idioma);
    if (btn_idioma === 'ES') {
      this.Cambiar_Espanol();
    } else if (btn_idioma === 'EN') {
      this.Cambiar_Ingles();
    }
  }

  Cambiar_Espanol() {
    this.Data = {
      titulo_inicio:
        'Uno de los conglomerados más grandes e importantes de Mexico y Estados Unidos',
      titulo_boton: 'Ver mas',
      nosotros: 'Acerca de nosotros',
      contactanos: 'Contactanos',
    };
  }

  Cambiar_Ingles() {
    this.Data = {
      titulo_inicio:
        'One of the largest and most important conglomerates in Mexico and the United States',
      titulo_boton: 'see more',
      nosotros: 'About us',
      contactanos: 'contact us',
    };
  }
}
